import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

// 获取网盘列表
export function fileNetdiskList(params) {
	return request({
		url: baseUrl + "/spider/web/fileNetdisk/list",
		method: "get",
		params: params
	});
}

// 获取服务类型列表
export function tagTreeList(params) {
	return request({
		url: baseUrl + "/spider/web/tag/treeList",
		method: "get",
		params: params
	});
}

// 机构列表查询 /spider/web/mechanism/list
export function mechanismList(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/deptTreeList",
		method: "get",
		data: params
	});
}

// 获取项目列表
export function itemList(params) {
	return request({
		url: baseUrl + "/spider/web/item/getMyItem",
		method: "get",
		params: params
	});
}


// 获取网盘内容详情
export function fileNetdisk(params) {
	return request({
		url: `${baseUrl}/spider/web/fileNetdisk/${params}`,
		method: "get",
	});
}