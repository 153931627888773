<template>
	<div id="NetworkDisk">
		<!-- 蜘蛛网盘 -->
		<div class="NetDiskSearth">
			<div class="searth">
				<el-input class="searthInput" v-model="searth" placeholder="输入设计需求关键词进行搜索" prefix-icon="el-icon-search">
					<el-button slot="append" @click="searchFun()">搜索</el-button>
					<!-- <el-button slot="append" @click="fastSelectFun">搜索</el-button> -->
				</el-input>
			</div>

			<div class="tagSelect">
				<template v-for="(item,i) in tagsList">
					<el-divider v-if="i!=0" :key="i" direction="vertical"></el-divider>
					<el-button :key="i" @click="tagSelectFun(item.id)"
						:type="tagVal.indexOf(item.id) != -1?'text':''">{{item.tagName}}</el-button>
				</template>
			</div>

			<div class="fastSelect">
				<div class="fastTit">快速查找</div>
				<div class="selectBox">
					<p>
						按领域查找
						<el-select v-model="mechanismVal" placeholder="请选择" size="mini" @change="fastSelectFun"
							filterable multiple clearable>
							<el-option v-for="item in mechanismList" :key="item.id" :label="item.tagName"
								:value="item.id"> </el-option>
						</el-select>
					</p>
					<p>
						按合规性查找
						<el-select v-model="itemVal" placeholder="请选择" size="mini" @change="fastSelectFun" filterable
							multiple clearable>
							<el-option v-for="item in itemList" :key="item.id" :label="item.tagName" :value="item.id">
							</el-option>
						</el-select>
					</p>
					<p>
						按支付标准查找
						<el-select v-model="dateVal" placeholder="请选择" size="mini" @change="fastSelectFun" filterable
							multiple clearable>
							<el-option v-for="item in dateType" :key="item.id" :label="item.tagName" :value="item.id">
							</el-option>
						</el-select>
					</p>
				</div>
			</div>
		</div>

		<div class="container-water-fall">
			<waterfall :col="col" :width="itemWidth" :gutterWidth="gutterWidth" :data="imgData" @loadmore="loadmore">

				<div class="cell-item" v-for="(item,i) in imgData" :key="i" @click="getFileNetdisk(item)">
					<img :src="item.imagePath"
						v-if="item.fileType == '图片' && item.fileSuffix != 'psd' && item.fileSuffix!='ai'">
					<div class="iconshow"
						v-else-if="item.fileType == '图片' && (item.fileSuffix == 'psd' || item.fileSuffix == 'ai')">
						<img :src="require('../../assets/images/psd.svg')" v-if="item.fileSuffix=='psd'">
						<img :src="require('../../assets/images/ai.svg')" v-else-if="item.fileSuffix=='ai'">
					</div>
					<div class="iconshow" v-else-if="item.fileType=='文档'">
						<!-- <img v-if="item.imagePath"
							src="https://psd.life-oasis.com/spider/spider/web/fileNetdisk/getOssFile/1934cc11-3890-469d-b377-71c366570223_9a1af03e-211c-441d-a70d-10f19718aa36.png" /> -->
						<img v-if="item.imagePath&&item.imagePath.includes('jpg')" :src="item.imagePath" />
						<!-- 	<el-image v-if="item.imagePath&&item.imagePath.includes('jpg')" :src="item.imagePath" lazy>
							<div slot="placeholder" class="imgLoading">
								加载中<span class="dot">...</span>
							</div>
						</el-image> -->
						<img :src="require('../../assets/images/Excel.svg')"
							v-else-if="item.fileSuffix=='xls'||item.fileSuffix=='xlsx'">
						<!-- <img :src="require('../../assets/images/Excel.svg')"
							v-if="item.fileSuffix=='xls'||item.fileSuffix=='xlsx'"> -->
						<img :src="require('../../assets/images/pdf.svg')" v-else-if="item.fileSuffix=='pdf'">
						<img :src="require('../../assets/images/ppt.svg')"
							v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'">
						<img :src="require('../../assets/images/word.svg')"
							v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='docx'">
						<img :src="require('../../assets/images/zip.svg')"
							v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'">
						<img :src="require('../../assets/images/default.svg')" v-else>
					</div>
					<div class="iconshow" v-else-if="item.fileType=='视频'">
						<img :src="require('../../assets/images/video.svg')">
						<!-- <i class="icon shipin"></i> -->
					</div>
					<div class="iconshow" v-else>
						<img :src="require('../../assets/images/zip.svg')"
							v-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'">
						<img :src="require('../../assets/images/default.svg')" v-else>
					</div>
					<div class="tit">{{item.title}}</div>
					<div class="info">
						<div class="user">
							<el-image :src="item.userAvatar" fit="cover"></el-image>
							<span>{{item.createBy}}</span>
						</div>
						<div class="type">
							<template v-for="(tag,n) in item.tags">
								<el-tag :key="n" :type="n==0?'info':''" v-if="n<1"> {{tag}} </el-tag>
							</template>
						</div>
					</div>
				</div>
			</waterfall>
			<div class="imgDataNone" v-if="!imgData[0]">
				<img src="../../assets/images/nores.png">

			</div>
			<p class="loading">
				<span v-if="lodingType=='show'">触底加载</span>
				<span v-if="lodingType=='loading'"><i class="el-icon-loading"></i>加载中...</span>
				<span v-if="lodingType=='none'">
					没有更多数据
				</span>
			</p>
		</div>

		<!-- <el-button type="text">打开嵌套表格的 Dialog</el-button> -->

		<!-- <el-dialog :custom-class="rowData.fileType=='图片'?'mainDialog':'mainDialog min'" :visible.sync="mainDialog"> -->
		<el-dialog custom-class="mainDialog min" :visible.sync="mainDialog">
			<div class="listBox">
				<div class="iconshow"
					v-if="rowData.fileType=='图片'&&rowData.fileSuffix!='psd'&&rowData.fileSuffix!='ai'">
					<img :src="rowData.imagePath">
				</div>
				<div class="iconshow" v-else-if="rowData.fileType=='图片'&&rowData.fileSuffix=='psd'">
					<img :src="require('../../assets/images/psd.svg')">
				</div>
				<div class="iconshow" v-else-if="rowData.fileType=='图片'&&rowData.fileSuffix=='ai'">
					<img :src="require('../../assets/images/ai.svg')">
				</div>
				<div class="iconshow" v-else-if="rowData.fileType=='文档'">
					<!-- <img v-if="rowData.imagePath" :src="rowData.imagePath"> -->
					<el-image v-if="rowData.imagePath&&rowData.imagePath.includes('jpg')" :src="rowData.imagePath">
						<div slot="placeholder" class="imgLoading">
							加载中<span class="dot">...</span>
						</div>
					</el-image>
					<img src="../../assets/images/Excel.svg"
						v-else-if="rowData.fileSuffix=='xls'||rowData.fileSuffix=='xlsx'">
					<img :src="require('../../assets/images/pdf.svg')" v-else-if="rowData.fileSuffix=='pdf'">
					<img :src="require('../../assets/images/ppt.svg')"
						v-else-if="rowData.fileSuffix=='ppt'||rowData.fileSuffix=='pptx'">
					<img :src="require('../../assets/images/word.svg')"
						v-else-if="rowData.fileSuffix=='doc'||rowData.fileSuffix=='docx'">
					<img :src="require('../../assets/images/zip.svg')"
						v-else-if="rowData.fileSuffix=='zip'||rowData.fileSuffix=='rar'">
					<img :src="require('../../assets/images/default.svg')" v-else>
				</div>
				<div class="iconshow" v-else-if="rowData.fileType=='视频'">
					<img :src="require('../../assets/images/video.svg')">
				</div>
				<div class="iconshow" v-else>
					<img :src="require('../../assets/images/zip.svg')"
						v-if="rowData.fileSuffix=='zip'||rowData.fileSuffix=='rar'">
					<img :src="require('../../assets/images/default.svg')" v-else>
				</div>
			</div>
			<div class="infoBox">
				<div class="tit">
					<span>{{rowData.title}}</span>
					<p class="time">{{rowData.createTime }}</p>
				</div>
				<ul class="cont">
					<li><span>作品分类</span><b>{{rowData.fileType}}</b> </li>
					<li><span>作品规格</span><b v-if="rowData.imageSize">{{rowData.imageSize}}</b> <b
							v-else>{{rowData.fileSuffix}}</b> </li>
					<li><span>关键词</span><b><el-tag v-for="(item,i) in rowData.tags" :key="i">{{item}}</el-tag></b> </li>
				</ul>
				<div class="opts">
					<el-button type="info" @click="preview(rowData)"
						v-if="'图片-视频-文档'.includes(rowData.fileType)||'pdf-ppt-pptx-doc-docx-xls-xlsx'.includes(rowData.fileSuffix)">
						<!-- v-if="rowData.fileType=='图片'||rowData.fileType=='视频'||rowData.fileSuffix=='pdf'||rowData.fileSuffix=='ppt'"> -->
						<i class="iconfont icon-xiazaitu"></i>预览
					</el-button>
					<el-button type="info" @click="downloadFile(rowData)" v-if="rowData.filePath">
						<i class="iconfont icon-xiazaitu"></i>下载
					</el-button>
				</div>
			</div>
		</el-dialog>
		<el-backtop :bottom="100" :visibility-height="50"></el-backtop>
	</div>
</template>

<script>
	import {
		fileNetdiskList, // 获取网盘列表
		tagTreeList, // 服务类型列表
		// itemList, // 获取项目列表
		// mechanismList, //机构列表   
		fileNetdisk //获取网盘内容详情
	} from '@/request/apiNetDisk.js';
	export default {
		name: 'NetworkDisk',
		components: {},
		data() {
			return {
				mainDialog: false,
				rowData: '',
				tags: [],
				tagVal: [],
				searth: '', // 关键词
				tagsList: [], // 类型列表
				mechanismList: [], // 领域列表
				itemList: [], // 合规性列表
				dateType: [], // 按支付标准查找
				mechanismVal: '',
				itemVal: [],
				dateVal: '',
				imgData: [], // 瀑布流展示列表
				col: 4,
				pageSize: 24, // 进步数
				pageNum: 1, // 当前加载页
				lodingType: 'show', // show:展示中   loading：加载中   none:没有更多
			}
		},
		created() {
			let viewWidth = window.innerWidth

			if (viewWidth < 1600) {
				this.col = 3
			} else {
				this.col = 4
			}
			if (this.$route.query.id) {
				this.getFileNetdisk({
					id: this.$route.query.id
				})
			}
			this.getTagTreeList();
			// this.geTmechanismList();
			// this.getitemList();
		},
		computed: {
			itemWidth() { // #rem布局 计算宽度
				return (document.documentElement.clientWidth - 350 - this.gutterWidth * (this.col)) / this.col
			},
			gutterWidth() { // #rem布局 计算x轴方向margin(y轴方向的margin自定义在css中即可)
				return 30
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll, true);
			this.getfileNetdiskList();
		},
		methods: {
			// search 关键词搜索
			searchFun() {
				this.imgData = [];
				this.lodingType = 'show';
				this.fastSelectFun();
			},

			// 预览
			preview(data) {
				// console.log(JSON.parse(JSON.stringify(data)))
				if ("pptx-ppt-doc-docx-xls-xlsx".includes(data.fileSuffix)) {
					window.open(
						`https://view.officeapps.live.com/op/view.aspx?src=${data.filePath ? data.filePath : data.imagePath}`,
						'_blank');
				} else {
					window.open(data.filePath ? data.filePath : data.imagePath, '_blank');
				}
			},

			//下载
			downloadFile(file) {
				console.log(file)
				if (!file.filePath) {
					return false;
				}

				let titleArr = file.title.split(".")
				if (titleArr.length > 1) {
					titleArr.pop();
				}
				let title = titleArr.join('');

				fetch(file.filePath).then(res => res.blob()).then(blob => {
					const a = document.createElement('a');
					document.body.appendChild(a)
					a.style.display = 'none'
					// 使用获取到的blob对象创建的url
					const url = window.URL.createObjectURL(blob);
					a.href = url;
					// 指定下载的文件名
					a.download = `${title}.${file.fileSuffix}`;
					a.click();
					document.body.removeChild(a)
					// 移除blob对象的url
					window.URL.revokeObjectURL(url);
				});

				// let url = window.URL.createObjectURL(new Blob([file.filePath]));
				// let link = document.createElement('a');
				// link.style.display = 'none';
				// link.href = url;
				// link.download = `${title}.${file.fileSuffix}`;
				// document.body.appendChild(link);
				// link.click();
				// link.remove();
			},

			// 打开网盘
			copyPsd(psd) {
				console.log(psd)
				const save = function(e) {
					e.clipboardData.setData('text/plain', psd)
					e.preventDefault() // 阻止默认行为
				}
				document.addEventListener('copy', save) // 添加一个copy事件
				document.execCommand('copy') // 执行copy方法
				this.$message({
					message: '复制成功',
					type: 'success'
				})
			},

			// 跳转报价
			toReference() {
				this.$router.push({
					name: 'Reference'
				})
			},

			// 获取网盘内容详情
			getFileNetdisk(item) {
				fileNetdisk(item.id).then(res => {
					if (res.code == 200) {
						this.mainDialog = true;
						// if (res.data.tags) {
						// 	res.data.tags = res.data.tags.split(",");
						// }
						this.rowData = res.data;
					}
				})
			},

			// 获取网盘筛选列表
			getTagTreeList() {
				tagTreeList({
					parentId: 999
				}).then(res => {
					if (res.code == 200) {
						res.rows.map(item => {
							if (item.id == 27) {
								this.tagsList = item.children;
							} else if (item.id == 26) {
								this.dateType = item.children;
							} else if (item.id == 25) {
								this.itemList = item.children;
							} else if (item.id == 24) {
								this.mechanismList = item.children;
							}
						})


					}
				})
			},

			fastSelectFun() {
				// console.log(this.tags)
				this.tags = []
				this.imgData = [];
				this.lodingType = 'show';
				this.pageNum = 1;

				let tagVal = JSON.parse(JSON.stringify(this.tagVal));
				let itemVal = JSON.parse(JSON.stringify(this.itemVal));
				let dateVal = JSON.parse(JSON.stringify(this.dateVal));
				let mechVal = JSON.parse(JSON.stringify(this.mechanismVal));
				this.tags = [...tagVal, ...mechVal, ...itemVal, ...dateVal]
				setTimeout(() => {
					this.getfileNetdiskList();
				}, 400)
			},

			// 获取瀑布流列表
			getfileNetdiskList() {
				let data = {};
				data.pageSize = this.pageSize;
				data.pageNum = this.pageNum;
				data.tags = this.tags[0] ? this.tags[0] : null;
				data.title = this.searth ? this.searth : null;
				data.isAsc = 'desc';
				data.orderByColumn = 'create_time';

				if (this.lodingType == 'show') {
					this.lodingType = 'loading'
					fileNetdiskList(data).then(res => {
						if (res.code == 200) {
							let resdata = res.rows
							resdata.map(item => {
								if (item.tags) item.tags = item.tags.split(',');
								return item
							})
							this.imgData = [...this.imgData, ...resdata];
							this.$forceUpdate();
						};
						if (res.rows[0]) {
							this.pageNum++;
							this.lodingType = 'show';
						} else {
							this.lodingType = 'none';
						};
					}).catch(err => {
						console.log(err)
						this.lodingType = 'show';
					})
				}
			},

			// 监听页面滚动触底
			handleScroll(e) {
				//变量scrollTop是滚动条滚动时，距离顶部的距离
				var scrollTop = e.target.scrollTop;
				//变量windowHeight是可视区的高度
				var windowHeight = e.target.clientHeight;
				//变量scrollHeight是滚动条的总高度
				var scrollHeight = e.target.scrollHeight;
				//滚动条到底部的条件
				if (scrollTop + windowHeight == scrollHeight) {
					//写后台加载数据的函数
					// console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
					// alert("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
					this.loadmore()
				}
			},

			tagSelectFun(item) {
				console.log(item)
				if (this.tagVal.indexOf(item) != -1) {
					this.tagVal = [];
				} else {
					this.tagVal = [item];
				}
				this.fastSelectFun();
			},



			// 改变现实列数
			switchCol(col) {
				this.col = col
				console.log(this.col)
			},

			// 加载更多
			loadmore() {
				setTimeout(() => {
					this.getfileNetdiskList();
				}, 300)
			},
			mix() {
				this.$waterfall.mix()
			}
		}
	}
</script>

<style lang="less">
	#NetworkDisk {
		min-width: 1200px;

		.NetDiskSearth {
			padding: 50px 50px 30px;
			background-color: #F4F4F4;

			.searth {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.searthInput {
					max-width: 700px;
					height: 60px;


					.el-input__inner {
						height: 60px;
						font-size: 16px;
						border-top-left-radius: 15px;
						border-bottom-left-radius: 15px;
					}

					.el-input-group__append {
						border-top-right-radius: 15px;
						border-bottom-right-radius: 15px;
						font-size: 18px;
					}
				}

				.callme {
					display: flex;
					flex-direction: column;
					font-size: 16px;
					color: #181B1A;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					margin-left: 50px;

					i {
						font-size: 21px;
						margin-bottom: 4px;
					}
				}
			}

			.tagSelect {
				margin-top: 30px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				.el-button {
					background-color: #fff;
					border: none;
					font-size: 18px;
					transition: all .3s;
					clear: both;
					color: #181B1A;
					padding: 12px 20px;

					&:hover {
						color: #fff;
						background: linear-gradient(90deg, #625bff, #c548ab);
					}
				}

				.el-button--text {
					color: #fff;
					background: linear-gradient(90deg, #625bff, #c548ab);
				}

				.el-divider {
					height: 36px;
					margin: 0 20px;
				}
			}

			.fastSelect {
				margin-top: 20px;
				padding: 20px;
				border-radius: 20px;
				background: rgba(104, 46, 220, 0.04);

				.fastTit {
					color: #181B1A;
					font-size: 18px;
				}

				.selectBox {
					margin-top: 20px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;

					p {
						padding-right: 50px;
						font-size: 14px;
						display: flex;
						align-items: center;

						.el-select,
						.el-cascader {
							margin-left: 4px;
						}

						.el-cascader {
							width: 220px;
						}

						&:hover {
							color: #682EDC;
						}
					}
				}
			}
		}

		.container-water-fall {
			padding: 30px 50px 30px;

			.imgDataNone {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.loading {
				text-align: center;
			}

			.opts {
				>* {
					font-size: 16px;
					margin-right: 20px;
				}
			}

			.cell-item {
				margin: 10px 5px 30px;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				cursor: pointer;
				box-shadow: 0 0 20px rgba(24, 27, 26, 0.1);
				border-radius: 10px;
				overflow: hidden;

				* {
					cursor: pointer;
					transition: all .3s;
				}

				.el-image,
				img {
					display: block;
					width: 100%;
				}

				.iconshow {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 20px 20px 0;


					img {
						height: 100px;
						object-fit: contain;
					}

					.imgLoading {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #F5F7FA;
						color: #c0c4cc;
						font-size: 14px;
					}
				}

				.tit {
					font-size: 20px;
					color: #181B1A;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin: 15px 20px;
				}

				.info {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px 13px;

					.user {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						flex: 1;

						.el-image {
							width: 20px;
							height: 20px;
							border-radius: 50%;
							background-color: #ccc;
							margin-right: 4px;
						}

						span {
							font-size: 16px;
							color: #181B1A;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					.type {
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
						align-items: center;

						.el-tag {
							padding: 0;
							line-height: 0;
							height: auto;
							background-color: #fff;
							border: none;
							margin-left: 4px;
							font-size: 14px;
							color: #181B1A;
						}

						.el-tag.el-tag--info {
							color: rgba(24, 27, 26, 0.6);
						}

						i {
							margin-left: 4px;
						}
					}
				}

				.options {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.fun {

						.el-button {
							color: #606266;

							span {
								display: flex;
								flex-direction: row;
								align-items: center;
							}
						}
					}
				}
			}
		}

		.mainDialog {
			max-width: 700px;
			border-radius: 20px;

			.el-dialog__header {
				padding: 0;
			}

			.el-dialog__body {
				display: flex;
				flex-direction: row;
				padding: 40px;

				.listBox {
					flex: 1;

					img {
						display: block;
						width: 100%;
						border-radius: 30px;
						max-height: 500px;
						object-fit: cover;
					}

					.iconshow {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						i {
							font-size: 140px;
						}
					}
				}

				.infoBox {
					width: 300px;
					margin-left: 40px;

					.tit {
						display: flex;
						flex-direction: column;
						padding-bottom: 16px;
						border-bottom: 1px solid #F2F2F2;
						margin-bottom: 16px;

						span {
							font-size: 18px;
							color: #181B1A;
							overflow: hidden; //超出的文本隐藏
							display: -webkit-box;
							-webkit-line-clamp: 2; // 超出多少行
							-webkit-box-orient: vertical;
						}

						.time {
							font-size: 12px;
							color: rgba(24, 27, 26, 0.6);
						}
					}

					.cont {
						display: flex;
						flex-direction: column;
						margin-bottom: 10px;

						li {
							display: flex;
							flex-direction: row;
							align-items: center;
							margin-bottom: 10px;

							>span {
								width: 50px;
								text-align: right;
								margin-right: 10px;
								color: rgba(24, 27, 26, 0.6);
							}

							b {
								flex: 1;
								color: rgba(24, 27, 26, 0.8);


								.el-tag {
									line-height: 1;
									color: rgba(24, 27, 26, 0.8);
									height: auto;
									background-color: #fff;
									border: 1px solid #181B1A;
									padding: 1px 2px;
									margin: 1px 5px 1px 0;
								}
							}
						}
					}

					.opts {
						display: flex;
						flex-wrap: wrap;

						.el-button {
							height: auto;
							background-color: #fff;
							color: #181B1A;
							border: none;
							font-size: 14px;
							padding: 10px;
							border: 1px solid #181B1A;
							margin: 0 10px 0 0;
						}



						.el-button--info {
							border: 1px solid #682EDC;
							color: #682EDC;
						}

						.el-button--primary {
							border: 1px solid #682EDC;
							color: #fff;
							background: #682EDC;
						}
					}
				}
			}
		}

		.mainDialog.min {}

	}
</style>
<style lang="less">
	.ckt-template-section {
		padding: 40px !important;
	}
</style>